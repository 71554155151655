.auth{
    background-color: black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth_ele{
    background-color: transparent;
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px;
    width: 240px;
    color: white;
    margin: 10px;
}
.auth_btn{
    background-color: white;
    font-weight: bolder;
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    margin-top: 20px;
    cursor: pointer;
}

.btns_admin{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.btn_s{
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}

#exam, #exam-inp, #subexam, #test, #marks, #level, #time, #cost{
    padding: 15px;
    min-width: 200px;
    width: 60vw;
    border-radius: 10px;
    border: transparent;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 10px;
}
.or{
    font-weight: bolder;
    margin: 15px;
}
.imp{
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 2px;
    margin: 20px;
}
#subm{
    background-color: blueviolet;
    padding: 10px;
    color: white;
    font-weight: bolder;
    border: transparent;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    margin-top: 20px;
}
.sria{

}
.form_imp{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}
.imp_inp{
    width: 260px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: 0.1px solid black;
    

}
.imp_sub{
    background-color: peru;
    padding: 10px;
    width: 120px;
    font-size: 15px;
    color: white;
    font-weight: bolder;
    border: transparent;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
}