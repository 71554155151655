.ana_c{
    /* border: 1px solid black; */
    border-radius: 15px;
    margin: 20px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ques_ana{
    text-align: left;
    font-weight: bold;
    font-size: 18px;
}
.ca{
    font-weight: bolder;
    color: green;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid green;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.ia{
    font-weight: bolder;
    color: red;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid red;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.sa{
    font-weight: bolder;
    color: blueviolet;
    margin-bottom: 20px;
    margin-left: 10px;
    border: 1px solid blueviolet;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.marks{
    background-color: blueviolet;
    color: white;
    font-size: 15px;
    padding: 4px;
    margin-left: 10px;
    border-radius: 5px;
}
.ana_nav{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
    padding-bottom: 20px;
    
}
.ana_nav_qa_r{
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
}
.ana_nav_qa_r_a, .ana_nav_qa_r_b, .ana_nav_qa_r_c,.ana_nav_qa_r_d{
    margin-right: 10px;
    margin-left: 10px;
}
.ana_nav_qa{
    font-weight: bold;
    font-size: 20px;
    margin: 10px
}
.ana_nav_qa_r_a{
    background-color: rgb(43, 162, 226);
    color: white;
    font-size: 15px;
    padding: 4px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: bolder;
    margin-top: 5px;
}
.ana_nav_qa_r_b{
    background-color: rgb(57, 60, 57);
    color: white;
    font-size: 15px;
    padding: 4px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: bolder;
    margin-top: 5px;
}
.ana_nav_qa_r_c{
    background-color: blueviolet;
    color: white;
    font-size: 15px;
    padding: 4px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: bolder;
    margin-top: 5px;
}
.ana_nav_qa_r_d{
    background-color: red;
    color: white;
    font-size: 15px;
    padding: 4px;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: bolder;
    margin-top: 5px;
}
.ana_a{
    background: linear-gradient(to right, #f4e7fc, #fbedec);
display: flex;
justify-content: space-between;
align-items: center;
margin: 20px;
flex-wrap: wrap;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

border-radius: 10px;
}
.ana_a_img{
    height: 150px;
}
.ana_a_l_h{
    font-size: 25px;
    font-weight: bolder;
}
.ana_a_l_b{
    display: flex;
    min-width: 280px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ana_a_l_b_ele{
    font-weight: 500;
    margin-top: 10px;
    border: 0.1px solid gray;
    padding: 5px;
    border-radius: 5px;

}
.comp{
    background-color: green;
    color: white;
    font-size: 15px;
    padding: 8px;
    border-radius: 10px;
    margin-left: 20px;
}
.ana_b{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.ana_b_l{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* width: 100%; */
    /* margin: 30px; */
    padding: 30px;
    border-radius: 10px;
}

@media screen and (min-width:800px) {
    .oo{
        width: 600px;
    }
    .ana_a_l_b{
        width: 300px;

    }
}
@media screen and (max-width:800px) {
    .oo{
        width: 200px;
    }
}