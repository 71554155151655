.table{
    width: 80vw;
    margin: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    gap: 10px;

}
.order_txt{
    text-align: left;
    margin: 20px;
    font-size: 30px;
}
.table_tr{
    background-color: #F4E8FC;
    border-radius: 10px;
}

tr{
    margin-top: 10px;
}
/* Assuming you have a CSS file, e.g., styles.css */
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd; /* Add border to the bottom of each cell */
  }
  
  tr:last-child td {
    border-bottom: none; /* Remove border from the last row */
  }
  
  tr {
    border: 1px solid #ddd; /* Add border to entire row */
  }
  
  /* Increase gap between rows */
  tr {
    margin-bottom: 10px;
  }
  