.register{
    display: flex;
    border: 1px solid black;
    /* width: 320px; */
    /* width: 100vw; */
    min-width: 320px;
    max-width: 80vw;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: white;
    flex-wrap: wrap;
    padding: 10px;
    border: 1px solid black;


}
.lg_or{
    margin-top: 10px;
}
.log{
    margin-top: 10px;
    text-decoration: underline;
    font-weight: bold;
}
.reg_bg{
    background-image: url("./grpbg.png");
    z-index: -1;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    

}
.ad_jt{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    
}
.grp{
    background-color: #f8e6ee;
    width: 29vw;
    min-width: 320px;
    /* border: 1px solid black; */
    margin: 20px;
 
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    
}
.reg_right{
    text-align: center;
    align-self: center;
    margin-top: 20px;

}
.reg_head{
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 3px;
}
.reg_body{
    color: rgb(174, 169, 169);
    font-weight: bold;
    margin-top: 5px;
    font-size: 15px;
    font-size: 300;
}
.sri{
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 18px;
    font-weight: bolder;
}
.reg_in_txt{
    font-size: 15px;
    margin-top: 20px;
    font-weight: bolder;
    text-align: left;
}

.in_reg{
    margin-top: 10px;
    padding: 15px;
    /* text-align: left; */
    
    /* max-width: 350px; */
    /* width: 280px; */
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
}
.reg_right{
    /* margin-right: 60px; */
}
.btn_ra{
    background-color: #8932cc;
    /* width: 120px; */
    text-align: center;
    padding: 15px;
    color: white;
    font-weight: bold;
    border-radius: 30px;
}
.btn_cna{
    text-decoration: underline;
    margin-top: 10px;
    font-size: 12px;
}
.btns_r{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.error{
    text-align: left;
    color: red;
    font-weight: bold;
    font-size: 10px;
    margin-top: 4px;
    margin-left: 5px;
}