/*
  Note: These are temporary previews of the images. 
  They're active for 10-15 days only. You should replace them with the actual images before using the code in production.
*/
.dialogClass{
  background-color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  border: 0.1px solid gray;
  
}
.classF{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btnClass{
  
  background-color: black;
  border: transparent;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  width: 150px;
}
.classF_ele{
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  border: 0.1px solid gray;
  padding: 10px;
  color: black;
  font-size: 15px;
  font-weight: 400;
}
.cards_dpp{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#name,#mobile,#state{
  background-color: transparent;
  border: 1px solid white;
  padding: 10px;
  margin: 10px;
  color: white;
  border-radius: 10px;
}
#name::placeholder{
  color: white;
}
#mobile::placeholder{
  color: white;
}
#state::placeholder{
  color: white;
}
#send_foot{
  background-color: white;
  border: transparent;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}
.nav_ele_a{
  display: flex;
}

.p-menu ul{
  background-color: white;
  padding: 10px;
  margin-right: 20px;
  border-radius: 10px;
  border: 0.1px solid gray;
}
.p-menu li{
  margin: 10px;
  background-color: white;
  color: black;
  font-weight: bolder;
  font-size: 16px;
}
.examModal{
  background-color: white;
  border: 0.1px solid gray;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* font-size: 20px; */
  font-weight: bolder;
}
.art_r, .art_l{
  /* border: 1px solid black; */
  border-radius: 10px;
  margin: 10px;
  overflow-y: scroll;
  max-height: 80vh;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  
}
.articles{
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
}

@media screen and (min-width:720px) {
  .articles{
    display: flex;
    /* flex-wrap: wrap; */
  }
}
@media screen and (max-width:720px) {
  .articles{
    display: flex;
    flex-wrap: wrap;
  }
}
.art_l_head{
  color: red;
  font-weight: bolder;
  font-size: 18px;

}
.art_ol{
  
  text-align: left;
}
.art_li{
  margin-top: 15px;
}
.art_link{
  text-decoration: none;
  color: black;
  font-weight: 500;
}
.dropdown-content{

  width: 98vw;
  margin-left: 15px;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  
  
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  /* border: 1px solid black; */
  background-color: white;
  flex-wrap: wrap;

  
}
.dp_up{
  font-size: 15px;
  font-weight: bolder;
}
.dp_ele{
  font-size: 15px;
  font-weight: 500;
}
.column{
  margin-top: 20px;
}
.down{
  background-color: transparent;
  border: transparent;
}
.hi_a{
  height: 250px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 15px;
margin: 40px;

}

.bare{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.ld{
  letter-spacing: 3px;
}
.img_cls{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.custom-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px 30px 8px 8px;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;
  cursor: pointer;
}

/* Custom arrow */
.custom-dropdown::after {
  content: '\25BC'; /* Down arrow character */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent click events on the arrow */
}
.accordion {
  width: 100%;
  margin-bottom: 10px;
  font-size: 10px;
  height: 50px;
}


.reg_bt_n{
  color: purple;
  font-weight: bold;
  background-color: transparent;
  border: transparent;
  text-decoration: none;
  margin-top: 5px;
  margin-left: -10px;
  
  
}
@media screen and (max-width:900px) {
  .reg_bt_n{
    font-size: 10px;
  }
  .dash_nav{
    display: none;
  }
  .nav_p{
    display: none;
  }
  .nav_p_small{

    display: flex;
    align-items: center;
    }
}
.dropdown-content{
  margin-top: 40px;
}
@media screen and (min-width:900px) {
  .nav_p{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav_p_small{
    display: none;
  }
}
  
  .search_exam{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
  .search_icon{
    font-size: 25px;
    margin-right: 10px;
  }
  .inp_srch{
    border-radius: 10px;
    border: transparent;
    padding: 10px;
    width: 200px;
  }
  .srch_txt{
    background-color: #FF750F;
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    margin-left: 20px;
  }
  

  .comp_1{
    background: linear-gradient(to right, #f4e7fc, #fbedec);
  display: flex;
  justify-content: space-around;
  padding: 40px;
  align-items: center;
  flex-wrap: wrap;
  }
  
  .comp_1_l{
    max-width: 600px;
  }

  .sb_btn{
    background: linear-gradient(to right, #FF750F, #FF4830);

  }
  .search_bar{
    width: 100%;
    font-size: 18px;
    margin-top: 50px;
    
  }

  .sb_inp{
    border: transparent;
    background-color: transparent;
  }

  .sb_1{
    font-size: 18px;
    margin-bottom: 30px;
  }
  .search_bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width:720px) {
    .sb_2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      max-width: 320px;
      min-width: 280px;
  
      padding: 10px;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin: auto;
    }
    .comp_1_l_h{
      font-size: 58px;
      font-weight: bolder;
      /* max-width: 600px; */
      text-align: left;
    }
    .comp_1_l_b{
      font-size: 18px;
      /* max-width: 600px; */
      text-align: left;
      line-height: 30px;
      margin-top: 30px;
    }
    .sb_icon{
      font-size: 25px;
    }
    
    .sb_btn{
      color: white;
      font-weight: bolder;
      padding: 10px;
      border-radius: 10px;
    }
    .comp3_h{
      font-size: 48px;
      font-weight: bold;
      text-align: left;
  padding: 21px;
      
  
    }
    .comp3_b{
      font-size: 18px;
      margin-top: 10px;
      text-align: left;
      padding-left: 20px;
  
  
      
      
    }
    .cls{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
    }
    .news{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
  
    }
    .testi{
      margin-left: 50px;
      margin-right: 50px;
      /* border: 1px solid black; */
      padding: 40px;
  
    }
    .com_m{
      font-size: 80px;
    }
  }
  @media screen and (min-width:520px) and (max-width:720px) {
    .comp_img{
      height: 300px;
      margin-top: 30px;
    }
    .comp_1_l_h{
      font-size: 58px;
      font-weight: bolder;
      /* max-width: 600px; */
      text-align: center;
    }
    .comp_1_l_b{
      font-size: 18px;
      /* max-width: 600px; */
      text-align: center;
      line-height: 30px;
      margin-top: 30px;
    }
   
    .sb_2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      max-width: 320px;
      min-width: 280px;
  
      padding: 10px;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin: auto;
    }
    .sb_icon{
      font-size: 25px;
    }
    .sb_btn{
      color: white;
      font-weight: bolder;
      padding: 10px;
      border-radius: 10px;
    }
    .comp3_h{
      font-size: 48px;
      font-weight: bold;
      text-align: center;
  padding: 21px;
      
  
    }
    
    .comp3_b{
      font-size: 18px;
      margin-top: 10px;
      text-align: center;
      padding-left: 20px;
  
  
      
      
    }
    .cls{
      margin-left: 50px;
      margin-right: 50px;
      /* border: 1px solid black; */
      padding: 40px;
    }
    .news{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
  
    }
    .testi{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
  
    }
  }
  @media screen and (max-width:520px) {
    .comp_img{
      height: 200px;
      margin-top: 30px;
    }
    
    .comp_1_l_h{
      font-weight: bolder;
      /* max-width: 600px; */
      text-align: center;
    }
    .comp_1_l_b{
      font-size: 18px;
      /* max-width: 600px; */
      text-align: center;
      line-height: 30px;
      margin-top: 30px;
    }
    .comp_1_l_h{
      font-size: 30px;
    }
    .sb_2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
    width: 220px;
  
      padding: 5px;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      /* margin: auto; */
    }
    .sb_icon{
      font-size: 30px;
    }
    .sb_btn{
      color: white;
      font-weight: bolder;
      padding: 10px;

      border-radius: 10px;
      font-size: 10px;
    }
    .comp3_h{
      font-size: 25px;
      font-weight: bold;
      text-align: center;
  padding: 21px;

      
  
    }
    
    .comp3_b{
      font-size: 18px;
      margin-top: 10px;
      text-align: center;
      padding-left: 20px;
  
  
      
      
    }
    .news{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
  
    }
    .testi{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
  
    }
    .cls{
      /* margin-left: 50px;
      margin-right: 50px; */
      /* border: 1px solid black; */
      padding: 40px;
    }
  }
  .comp_2{
    margin-top: 80px;
    padding: 10px;
  }

  .comp_2_h{
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .comp_2_b{
    font-size: 18px;
    margin-bottom: 30px;
  }
  .comp2_card{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
padding: 30px;
  }
  .comp2_card_ele{
    margin: 10px;
    font-size: 18px;
  }
  
  .comp2_txt_2{
    margin-top: 10px;
  }
  .comp2_txt_1{
    margin-top: 10px;
  }
  .comp2_cards{
    margin: 50px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }
  .comp3{
    background-color: #FFF7EF;
    text-align: left;
    padding: 40px;
    margin-top: 60px;
  
  }
  

  .comp3_cnt{
    color: #8932CC;
    font-weight: bolder;
    font-size: 25px;
    
  }
  .comp3_card{
    /* width: 280px; */
    width: 25vw;
    min-width: 280px;
    margin: 10px;
    /* height: 150px; */
    background-color: white;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
font-weight: bolder;

  }
  .comp3_txt{
    font-size: 25px;
    padding-right:  60px;
    border-top: 0.4px solid black;
    margin-top: 20px;
    padding-top: 20px;
    font-weight: 400;

  }
  .comp3_cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 80px;
    margin-bottom: -150px;
  }

  @media screen and (min-width:560px) {
    .comp4_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding-top: 40px;
      padding-right: 60px;
      text-align: left;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 20px;
      max-width: 480px;
  margin: 19px;
      /* margin: 30px; */
      /* width: 35vw; */
      
    }
  }
  @media screen and (max-width:560px) {
    .comp3a
    {
      display: none;
    }
    .comp4_card{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
    
      text-align: left;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 20px;
      max-width: 480px;
  margin: 19px;
      /* margin: 30px; */
      /* width: 35vw; */
      
    }
    .comp4_r{
      text-align: center;
      padding: 10px;
    }
    .cls_t{
      font-size: 25px;
    }
  }
 
  .comp4_cards{
    display: flex;
    justify-content: center;
    align-items: center ;
    flex-wrap: wrap;
    
  }

  .comp4_h{
    font-size: 30px;
    font-weight: bolder;
    margin-bottom: 30px;
    
  }
  .comp4{
    margin-top: 150px;
    /* padding: 50px; */
    margin-left: 50px;
    margin-right: 50px;
    /* border: 1px solid black; */
    padding: 40px;
  }
  .comp4_b{
    font-size: 18px;
    color: gray;
    /* width: 280px; */
    
  }
  .comp4_h_a{
    font-size: 38px;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  
  .comp4_b_a{
    font-size: 18px;
    margin-bottom: 50px;
  }

  .comp5{
    background: linear-gradient(to bottom, #f4e7fc, #fbedec);
    margin-left: 50px;
    margin-right: 50px;
    /* border: 1px solid black; */
    padding: 40px;
    border-radius: 20px;
    margin-top: -250px;
    padding-top: 250px;
  }
  .comp5_card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    /* width: 280px; */
    font-size: 18px;
    padding: 20px;
    background-color: white;
    text-align: left;
    margin: 19px;
    max-width: 340px;

  }
  .cm_5_a{
    height: 80px;
    background-color: orange;
    border-radius: 100%;
  }
  .comp5_b{
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .cm_5_b{
    margin-left: 10px;
  }
  .cm_5_ba{
    font-weight: bolder;
    margin-bottom: 3px;
  }
  .comp5_a, .cm_5_bb{
    color: #4F4F4F;
  }
  .comp5_cards{
    display: flex;
    justify-content: center;
    align-items: center ;
    flex-wrap: wrap;
    margin-top: 50px;
    

  }
  .comp5_a{
    line-height: 25px;
  }
  .quote{
    color: #f4e7fc;
    font-size: 40px;
    margin-bottom: -25px;
  }
  .cls_card_h{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .cls_card_b{
    font-size: 15px;
    color: #737373;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;

  }
  .rbd{
    font-weight: bolder;
    text-align: center;
    margin-top: 20px;
    border-top: 0.1px solid gray;
    padding-top: 10px;
  }
  .cls_c_b{
    border: 1px solid gray;
    border-radius: 10px;
    width: 120px;
    margin: auto;
  }

  .cls_card_c{
    width: 100px;
    color: white;
    font-weight: bolder;
    background-color: #EE7C00;
    font-size: 12px;
    border-radius: 5px;
    padding: 4px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    margin-top: 20px;
    margin: 10px;
  }
  .cls_card_d{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    height: 130px;
    
  }
  .crp{
    cursor: pointer;
  }
  .cls_card{
    border: 0.5px solid black;
    width: 280px;
    height: 380px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
margin: 10px;
cursor: pointer;
   padding: 15px;
    text-align: left;
  }
  .cards_cls{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }

  .vall{
    margin-top: 50px;
    max-width: 200px;
    color: white;
    font-weight: bolder;
    background-color: #EE7C00;
    font-size: 20px;
    border-radius: 5px;
    padding: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin: auto;
  }
  
  .news_card{
    background-color: #F6F6F6;
    border: 0.2px solid #C0C0C0;
    max-width: 400px;
    border-radius: 20px;
  }
  .news_h{
    font-size: 40px;
    text-align: left;
  }
  .news_card{
    padding: 30px;
    text-align: left;
    margin: 20px;
  }
.nc_h{
  font-size: 20px;
  font-weight: bold;
}
.nc_b{
  margin-top: 20px;
  font-size: 18px;
  color: #595858;
}
.nc_dd{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.nc_icon{
  font-size: 20px;
}
.news_cards{
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.t_l_a{
  color: rgb(244, 134, 0);
  font-weight: bolder;
  font-size: 20px;
  padding: 20px;
}

.test_l{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.testi{
  background: linear-gradient(to bottom, #f4e7fc, #fbedec);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.t_l_c{
  margin-top: 120px;
}
.p-accordion-header-text{
  color: black;
  font-size: 20px;
  font-weight: bolder;
  text-align: left;
  margin-left: 15px;
  background-color: black;
  color: white;
  border-radius: 10px;
/* width: 60vw; */
  padding: 15px;
  margin-top: 30px;
  width: 100%;
}
.p-component{
  text-align: left;
  font-size: 20px;
  
  line-height: 30px;
}
.p-icon{
  color: black;
  margin-top: 30px;

}
.testi_r{
  width: 60%;
}
@media screen and (max-width:1086px) {
  .t_l_c{
display: none;
  }
}

@media screen and (max-width:1345px) {
  .testi{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .t_l_a{
    text-align: center;
    margin: auto;
  }
  .mng_t{
    text-align: center;
    
  }
}
.footer{
  background-color: #370062;
  padding: 30px;
  margin-top: 80px;

}
.f_c{
  display: flex;
  justify-content: center;
  align-items: center;
}
.f_c_icon{
  color: white;
  font-size: 20px;
  margin: 10px;
  margin-bottom: 20px;
  
  
}
.f_bt{
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  color: white;
  flex-wrap: wrap;
}
.f_bt_ele{
  margin:20px
}
.line_f{
  height: 0.2px;
  background-color: gray;
  width: 80%;
  margin: auto;
}
.f_a{
  font-size: 30px;

  color: white;
  font-weight: bolder;
}
.f_b{
  color: white;
  font-size: 18px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  margin-left: 50px;
}
@media screen and (min-width:560px) {
  
}
@media screen and (max-width:560px) {
  .nav_p_small{
    margin-top: 2px;
  }
}

.nav_ele_b
{
  margin-left: 20px;
  font-size: 15px;
  margin-right: 20px;
  font-size: 15px;
}
.nav_ele_a{
  font-size: 20px;
  font-weight: bolder;
}
.nav_ele_c{
  border: 2px solid white;
  color: white;
  padding: 8px;
  margin: 10px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  background-color: transparent;
}
.nav_a{
  background-color: #8932CC;
  display: flex;
  justify-content: space-between;
}

.btn_auth{
  display: flex;
}
.nav_aa{
  display: flex;
  color: white;
  align-items: center;
}
.txt_p,.phone{
  margin: 10px;
}
.sri{
  width: 250px;
  margin-top: 13px;
  margin-right: 80px;
}
.map
{
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.map_ele_a{
  font-size: 50px;
  font-weight: bolder;
}
.map_l{
  text-align: left;
}
.temple{
  font-size: 30px;
  margin-bottom: -5px;
}
.map_ele_b{
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: gray;
  margin-bottom: 20px;
}