.panel_l{
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: flex-start;
    padding: 5px;
    align-items: center;
    border-radius: 10px;
    margin-left: 30px;
    height: 80vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}
.pagina_l{
    
}
.dd{
    width: 200px;
    padding: 10px;
    margin: 20px;
    margin-left: 30px;
    border-radius: 10px;
    border: transparent;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}
.panel_r{
    display: flex;
    flex-direction: column;
width: 100%;
}
.panel_cards{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
}
.panel{
    display: flex;
    width: 100%;
    justify-content: center;
    /* align-items: center; */
    
}


.panel_l_ele{
    width: 140px;
    /* border: 1px solid darkblue; */
    margin: 10px;
    display: flex;
    align-items: center;
    
}
.panel_txt{
    margin-left: 10px;
}
.logout{
    margin-top: 20vh;
}

@media screen and (min-width:720px) {
    .small_pl{
    display: none;
    }
    /* .icon_panel{
        border-radius: 50px;
        background-color: #F4E8FC;
        padding: 10px;
        color: purple !important;
        
    } */
    .icon_panel {
        border-radius: 50%;
        background-color: #F4E8FC;
        padding: 5px; /* Adjust padding to ensure the icon fits */
        width: 30px; /* Adjust width to fit the icon */
        height: 30px; /* Adjust height to fit the icon */
        color: purple !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
}

/* .get_panel{
    margin-right: -50px;
    
} */
.get_panel{
    position: absolute;
    left: 40px;
    top: 120px;
}
@media screen and (max-width:720px) {
    .big_pl{
        display: none;
    }
    /* .panel_l{
        margin-top: 80px;
    } */
    
    .p-icon{
        margin-top: 100px !important;
    }
    .icon_panel{
        border-radius: 50px;
        background-color: #F4E8FC;
        padding: 2px;
        font-size: 25px;
        width: 30px;
        height: 30px;
        /* padding: 10px; */
        color: purple !important;
        
    }
   
}
.profile_l{
    /* border: 1px solid black; */
    border-radius: 10px;
    background-color: gainsboro;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin: 30px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.profile_r{
    display: flex;
    flex-direction: column;
  
}
.inp_prof{
    width: 240px;
    padding: 10px;
    border: transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-radius: 10px;
margin: 20px;
}
.upd_btn{
    background-color: rgba(159, 15, 159, 0.801);
    color: white;
    font-weight: bolder;
    width: 120px;
    padding: 10px;
    border-radius: 10px;
    margin: 20px;
}