.ts_a {
  display: flex;

  justify-content: space-between;
  align-items: center;
  /* margin: 30px; */
  padding: 40px;
}

.ts_a_txt {
  font-weight: bolder;
  font-size: 20px;
}
.ts_a_icon {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}
.icon {
  font-size: 24px;
  margin-left: 10px;
  margin-top: 5px;
}

.ts_b {
  background: linear-gradient(to right, #e6dbed, #f7eaff);
  /* For vendor prefixes */
  background: -webkit-linear-gradient(to right, #e6dbed, #f7eaff);
  background: -moz-linear-gradient(to right, #e6dbed, #f7eaff);
  background: -o-linear-gradient(to right, #e6dbed, #f7eaff);
  background: -ms-linear-gradient(to right, #e6dbed, #f7eaff);
  /* Add fallback color */
  background-color: #e6dbed; /* Fallback for older browsers */
  /* Specify width and height or other styles as needed */

  margin: auto;
  text-align: left;
  font-weight: bolder;
  margin: 30px;
  padding: 30px;
  border-radius: 15px;
}
.ts_b_txt_2 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.dl_icon {
  color: #ff6e20;
  margin-right: 10px;
}
.ts_b_txt_2_a {
  color: #808089;
  font-weight: 400;
}
.ts_b_txt {
  font-size: 30px;
}
.qna_r {
  border: 0.1px solid black;
  border-radius: 5px;
  width: 300px;
  margin: 40px;
}
.qna_r_h {
  background-color: #f2f2f2;
  font-weight: bolder;
  padding: 10px;
  text-align: left;
  font-size: 15px;
}
.qna_bubble {
  width: 50px;
  margin: 10px;
  background-color: #e8e8e8;
  color: #91919c;
  font-weight: bold;
  border-radius: 10px;
  padding: 2px;
}
.qna_r_b {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
}
.ques {
  display: flex;
  font-weight: bolder;
  /* height: 400px; */
  font-size: 20px;
}
.in {
  color: gray;
  margin-right: 10px;
}
.qna_l {
  border: 0.5px solid black;
  width: 60%;
  padding: 10px;
  margin: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.qna {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
}
.options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.label_a {
}
.opt {
  /* border: 1px solid black; */
  text-align: left;
  font-size: 18px;
  font-weight: bolder;
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  /* padding: 10px; */
  padding-left: 0;
  border-radius: 10px;
  border: 0.5px solid black;
  /* flex-wrap: wrap; */
  height: 40px;
}
.af {
  display: flex;
  align-items: center;
  justify-content: center;
}
.purple {
  background-color: #8932cc;
  color: white;
}
.orange {
  background-color: #ff6e20;
  color: white;
}
.gr {
  background-color: #c4c4c4;
  /* padding: 10px;     */
  height: 40px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.gs {
  height: 40px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
  background-color: gray; /* Your desired background color */
}
.erro {
  height: 40px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
  background-color: red; /* Your desired background color */
}
.selected {
  height: 40px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
  background-color: #187432; /* Your desired background color */
}

input#option0 {
  margin-right: 10px;
}
input#option1 {
  margin-right: 10px;
}
input#option2 {
  margin-right: 10px;
}
input#option3 {
  margin-right: 10px;
}

.qna_bot {
  display: flex;
  justify-content: space-between;
  border-top: 0.3px solid black;
  flex-wrap: wrap;
}
.qna_bot_a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer_ic {
  margin-right: 10px;
  margin-top: 4px;
}
.qna_bot_btn {
  background-color: #8932cc;

  color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.sbt_btn {
  background-color: #ff6e20;
  width: 120px;
  margin: auto;
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.qna_r_b {
  height: 300px; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  flex-wrap: wrap;
  width: 300px;
}

/* Optional: To style scrollbar */
.qna_r_b::-webkit-scrollbar {
  width: 8px; /* Width of scrollbar */
}

.qna_r_b::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Color of scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.ques_ana{
    
}