.vsc{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px;
  text-align: left;
    border-radius: 20px;
    
    overflow-y: scroll;
    
   
}
.mb50{
    margin-bottom: 50px;
}
.vsc_ele{
    margin-bottom: 20px;
    font-weight: 500;
}

.news_anchor{
    text-decoration: none;
    color: black;
    text-align: left;
}
@media screen and (min-width:720px) {
    .vsc{
        width: 40vw;
        height: 50vh;
    }
    .news_if{
        width: 400px;
        height: 200px;
    }
}
@media screen and (max-width:720px) {
    .vsc{
        width: 250px;
        height: 50vh;
    }
    .news_if{
        width: 200px;
        height: 200px;
    }
}
.nav{
    margin-bottom: 50px;
}
.news_anchor{

}
.vsc_t{
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    justify-content: space-around;
}
.vss{
    display: flex;
    width: 100vw;
    /* height: 100vh; */
    justify-content: space-around;
    flex-wrap: wrap-reverse;
    
    align-items: flex-start;
}
.vscc{

}
.wn{
    font-size: 25px;
    font-weight: bolder;
    margin-bottom: 30px;
    text-align: left;
}