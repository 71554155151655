.cardM_bb{
    display: flex;
    align-items: center;
    /* width: 250px; */
    text-align: left;
    margin: 15px;
    margin-top: 5px;
}
.cardM_b_b{
    margin-left: 10px;
}
.cardM_b{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.cardM_b_a{
    color: purple;
    font-size: 12px;
    font-weight: bold;
}
.cardM_b_c{
    color: orange;
    font-size: 12px;
    font-weight: bold;
}
.cardM_b_d{
    color: green;
    font-size: 12px;
    font-weight: bold;
}
.cardM_b_e{
    color: blue;
    font-size: 12px;
    font-weight: bold;
}
.cardM{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    max-width: 400px;
    margin: 20px;
}
.cardM_h{
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
    padding-top: 10px;
    margin: 15px;
    border-bottom: 1px dotted black;
    padding-bottom: 10px;
}
.sn{
    background-color: #F7EAFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    color: purple;
    border: transparent;
    cursor: pointer;
}
