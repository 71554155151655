.blog_title{
    font-size: 20px;
    font-weight: bolder;
    margin: 20px;
}
.blog_body{
    text-align: left;
    margin: 20px;
    margin-top: 50px;
    
}
.blog{
    padding: 20px;
    font-size: 15px;
    
}
.blog_a{
    
    /* min-height: 200vh; */
}